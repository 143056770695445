import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Typography, AnimatedLink } from '../components/common';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Typography variant="h1" >Ups!</Typography>
    <Typography variant="h2" >This page does not exist! <span role="img" aria-labelledby="ups" aria-hidden="false">😅</span></Typography>
    <Typography variant="h3" >Why don't you <AnimatedLink paintDrip fontcolor="red" fontSize="20" to="/">go home?</AnimatedLink></Typography>
  </Layout>
)

export default NotFoundPage
